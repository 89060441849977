<template>
  <section
    v-if="variants.v1 === variant && sidebarComponents.length === 0"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div
      class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-p-6 lg:tw-w-full lg:tw-gap-24"
      :class="vars.isReversedBool ? 'lg:tw-flex-row-reverse' : 'lg:tw-flex-row'"
    >
      <div class="tw-w-full lg:tw-hidden">
        <LibraryImage
          :w-ratio="4"
          :h-ratio="5"
          :src="vars.photoImage"
          class="tw-h-auto tw-w-full"
          style="object-fit: cover; border-radius: var(--rounded)"
        />
      </div>

      <div class="tw-w-full lg:tw-hidden">
        <div
          v-html="vars.titleHtml"
          class="tw-mb-6 tw-mt-3 tw-flex tw-items-start tw-justify-start tw-gap-3 tw-text-start md:tw-mb-10"
        ></div>
      </div>

      <div class="tw-hidden tw-w-full lg:tw-block lg:tw-max-w-[515px]">
        <LibraryImage :w-ratio="4" :h-ratio="5" :src="vars.photoImage" style="border-radius: var(--rounded)" />
      </div>

      <div class="tw-flex tw-w-full tw-flex-col">
        <div
          v-html="vars.titleHtml"
          class="tw-mb-6 tw-mr-auto tw-flex tw-hidden tw-items-start tw-justify-start tw-gap-3 tw-text-start md:tw-mb-10 lg:tw-flex"
        ></div>

        <library-form v-if="form" :form="form" version="v1" :component="component"></library-form>
      </div>
    </div>
  </section>

  <div
    v-else-if="variants.v2 === variant && sidebarComponents.length > 0"
    class="tw-p-6"
    :style="`background-color: var(--c-${vars.backgroundColor});border-radius: var(--rounded);color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-mb-4 tw-flex tw-flex-col tw-items-start tw-self-start tw-text-start" v-html="vars.titleHtml"></div>

    <library-form v-if="form" :form="form" version="v1" :component="component"></library-form>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'ContactForm',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.contactForm);
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),
  },
});
</script>
