<template>
  <section v-if="variants.v1 === variant && sidebarComponents.length === 0"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-flex tw-max-w-xl tw-items-center tw-flex-col-reverse tw-gap-6 lg:tw-max-w-none lg:tw-flex-row lg:tw-gap-16 xl:tw-gap-32 tw-text-black"
      :class="{ 'lg:tw-flex-row-reverse': vars.isReversedBool }"
    >
      <div class="tw-relative tw-flex-[1_1_50%]"
      :class="{'tw-mr-10' : vars.thumbnailCornerText === 'bottom-right' || 'top-right'},
              {'tw-ml-10' : vars.thumbnailCornerText === 'bottom-left' || 'top-left'},
              {'tw-mt-8 lg:tw-mt-0' : vars.thumbnailCornerText === 'top-left' || 'top-right'}">
        <NuxtImg
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          class="tw-h-auto tw-w-full "
        />
        <library-video-embed v-if="vars.videoUrl"
          :video-url="vars.videoUrl"
          title="Video Popup"
          :is-popup="true"
          :cover-photo="vars.photoImage ? vars.photoImage : null"
        />
        <NuxtImg
          v-if="vars.thumbnailImage"
          :src="vars.thumbnailImage"
          class="tw-absolute tw-h-auto tw-w-1/2 tw-transform"
          :class="{'tw-translate-y-1/4 tw-border-r-8 tw-border-t-8 -translate-x-1/4 -tw-left-10 tw-bottom-0': vars.thumbnailCornerText === 'bottom-left'},
                  {'tw-translate-y-1/4 tw-border-l-8 tw-border-t-8 -tw-right-10 -translate-x-1/4 tw-bottom-0': vars.thumbnailCornerText === 'bottom-right'},
                  {'tw-translate-y-1/4 tw-border-r-8 tw-border-b-8 -translate-x-1/4 -tw-left-10 -tw-top-1/4': vars.thumbnailCornerText === 'top-left'},
                  {'tw-translate-y-1/4 tw-border-l-8 tw-border-b-8 -tw-right-10 -translate-x-1/4 -tw-top-1/4': vars.thumbnailCornerText === 'top-right'}"
          :style="`border-color: var(--c-${vars.backgroundColor})`"
        />
      </div>
      <div class="tw-flex tw-flex-[1_1_50%] tw-flex-col tw-gap-3 lg:tw-gap-6">
        <div v-html="vars.titleHtml" class="tw-flex tw-flex-row tw-flex-wrap"></div>
        <div v-if="descriptions" class="tw-flex tw-flex-col tw-gap-4">
          <p
            v-for="(item, index) in descriptions"
            :key="`infobox-description-${index}`"
            class="tw-max-w-2xl"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant && sidebarComponents.length === 0"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <div
      class="tw-w-full tw-h-full tw-mx-auto tw-grid tw-grid-cols-12 tw-gap-8 md:tw-gap-12 lg:tw-gap-20"
      :style="`color: var(--c-${vars.textColor})!important;`"
    >
      <div class="tw-col-span-12 tw-flex lg:tw-col-span-6 tw-self-center" style="aspect-ratio: 16/10;" :class="{ 'xl:tw-order-last': vars.isReversedBool }">
        <MotionGroup preset="slideVisibleBottom" :duration="800" is="div" class="tw-h-full tw-w-full">
          <LibraryPopupsVideoPopup
            v-if="vars.isPopupBool && vars.videoUrl"
            :videoUrl="vars.videoUrl"
            v-model:is-visible="isVisible"
            :portrait="vars.isVerticalBool"
          />

          <div
            v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
            class="tw-relative tw-mb-6 tw-h-full tw-w-full tw-overflow-hidden tw-rounded-[var(--rounded)] lg:tw-mb-0"
            @click="isVisible = true"
          >
            <LibraryImage
              :src="vars.photoImage"
              :alt="vars.titleText"
              class="tw-h-full tw-w-full tw-object-cover tw-cursor-pointer"
              :w-ratio="vars.isVerticalBool ? 9 : 3"
              :h-ratio="vars.isVerticalBool ? 16 : 2"
            />
            <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
              <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
                <circle cx="81" cy="81" r="81" fill="white" />
                <polygon points="65,45 115,81 65,117" fill="black" />
              </svg>
            </button>
          </div>

          <div
            v-if="vars.photoImage && !vars.videoUrl"
            class="tw-relative tw-mb-6 tw-h-full tw-w-full tw-overflow-hidden tw-rounded-[var(--rounded)] lg:tw-mb-0"
          >
            <LibraryImage
              :src="vars.photoImage"
              :alt="vars.titleText"
              class="tw-h-full tw-w-full tw-object-cover"
              :w-ratio="vars.isVerticalBool ? 9 : 645"
              :h-ratio="vars.isVerticalBool ? 16 : 430"
            />
          </div>

          <iframe
            v-if="!vars.isPopupBool && vars.videoUrl"
            class="tw-mb-6 tw-h-full tw-w-full tw-rounded-[var(--rounded)] tw-object-cover lg:tw-mb-0"
            :src="vars.videoUrl"
            :style="vars.isVerticalBool ? 'aspect-ratio:5/8;' : 'aspect-ratio: 16/9;'"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </MotionGroup>
      </div>

      <MotionGroup preset="fadeVisible" :duration="1000" is="div"
        class="tw-col-span-12 tw-self-center tw-flex tw-flex-col tw-justify-end lg:tw-col-span-6"
        :class="{ 'xl:tw-order-first': vars.isReversedBool }"
      >
        <div class="tw-flex tw-flex-col tw-gap-6">
          <div v-if="vars.titleHtml" class="tw-break-words ">
            <div v-html="vars.titleHtml"></div>
          </div>

          <div class="tw-prose tw-max-w-none tw-flex tw-flex-col tw-gap-4">
            <p
              v-for="(item, index) in descriptions"
              :key="`infobox-description-${index}`"
              class="tw-whitespace-normal tw-break-words  last:tw-mb-0 lg:tw-whitespace-pre-line"
            >
              {{ item.description }}
            </p>
          </div>

          <nuxt-link
            v-if="vars.buttonLinkUrl"
            :external="true"
            :class="`n-${vars.buttonTypeText}`"
            :to="vars.buttonLinkUrl"
          >
            {{ vars.buttonLabelText }}
          </nuxt-link>
        </div>
      </MotionGroup>
    </div>
  </section>
  <div
    v-if="variants.v2 === variant && sidebarComponents.length > 0"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}; border-radius: var(--rounded)`" class="tw-p-6"
  >
    <div
      class=" tw-grid tw-grid-cols-1 tw-gap-8"
      :style="`color: var(--c-${vars.textColor})!important;`"
    >
      <div class="tw-col-span-1 tw-flex " >
        <MotionGroup preset="slideVisibleBottom" :duration="800" is="div" class="tw-h-full tw-w-full">
          <LibraryPopupsVideoPopup
            v-if="vars.isPopupBool && vars.videoUrl"
            :videoUrl="vars.videoUrl"
            v-model:is-visible="isVisible"
            :portrait="vars.isVerticalBool"
          />

          <div
            v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
            class="tw-relative tw-mb-6 tw-h-full tw-w-full tw-overflow-hidden tw-rounded-[var(--rounded)]"
            @click="isVisible = true"
          >
            <LibraryImage
              :src="vars.photoImage"
              :alt="vars.titleText"
              class="tw-h-full tw-w-full tw-object-cover tw-cursor-pointer"
              :w-ratio="vars.isVerticalBool ? 9 : 3"
              :h-ratio="vars.isVerticalBool ? 16 : 2"
              style="border-radius: var(--rounded);"
            />
            <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
              <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
                <circle cx="81" cy="81" r="81" fill="white" />
                <polygon points="65,45 115,81 65,117" fill="black" />
              </svg>
            </button>
          </div>

          <div
            v-if="vars.photoImage && !vars.videoUrl"
            class="tw-relative tw-mb-6 tw-h-full tw-w-full tw-overflow-hidden tw-rounded-[var(--rounded)]"
          >
            <LibraryImage
              :src="vars.photoImage"
              :alt="vars.titleText"
              class="tw-h-full tw-w-full tw-object-cover"
              :w-ratio="vars.isVerticalBool ? 9 : 645"
              :h-ratio="vars.isVerticalBool ? 16 : 430"
              style="border-radius: var(--rounded);"
            />
          </div>

          <iframe
            v-if="!vars.isPopupBool && vars.videoUrl"
            class="tw-mb-6 tw-h-full tw-w-full tw-rounded-[var(--rounded)] tw-object-cover "
            :src="vars.videoUrl"
            :style="vars.isVerticalBool ? 'aspect-ratio:5/8;' : 'aspect-ratio: 16/9;'"
            style="aspect-ratio: 16/9; border-radius: var(--rounded);"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </MotionGroup>
      </div>

      <MotionGroup preset="fadeVisible" :duration="1000" is="div"
        class="tw-col-span-1 tw-flex tw-flex-col tw-justify-end "
      >
        <div class="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-6">
          <div v-if="vars.titleHtml" class="tw-break-words ">
            <div v-html="vars.titleHtml"></div>
          </div>

          <div class="tw-prose tw-max-w-none">
            <p
              v-for="(item, index) in descriptions"
              :key="`infobox-description-${index}`"
              class="tw-whitespace-normal tw-break-words  last:tw-mb-0"
            >
              {{ item.description }}
            </p>
          </div>

          <nuxt-link
            v-if="vars.buttonLinkUrl"
            :external="true"
            :class="`n-${vars.buttonTypeText} tw-w-auto`"
            :to="vars.buttonLinkUrl"
          >
            {{ vars.buttonLabelText }}
          </nuxt-link>
        </div>
      </MotionGroup>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'InfoBox',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      isVisible: false,
    };
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },

    ...mapState(useWebsiteStore, ['sidebarComponents']),
  },
});
</script>

