<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`${keyPrefix}-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
// common
import Separator from '~/components/library/Separator.vue';
import FormContainer from '~/components/library/FormContainer.vue';
import InfoCard from '~/components/theme5/components/InfoCard.vue';
import LatestProperties from '~/components/theme5/properties/LatestProperties.vue';
import MeetCard from '~/components/theme5/components/MeetCard.vue';
import ContentBox from '~/components/theme5/components/ContentBox.vue';
import OurGuide from '~/components/theme5/components/OurGuide.vue';
import SmallBox from '~/components/theme5/components/SmallBox.vue';
import InfoBox from '~/components/theme5/components/InfoBox.vue';
import BlogSection from '~/components/theme5/blogs/BlogSection.vue';
import AgentCard from '~/components/theme5/components/AgentCard.vue';
import ContactForm from '~/components/theme5/components/ContactForm.vue';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },

    keyPrefix: {
      required: false,
      type: String,
      default: 'dynamicComponent',
    },
  },

  components: {
    Separator,
    FormContainer,
    InfoCard,
    AgentCard,
    ContactForm,
    LatestProperties,
    BlogSection,
    InfoBox,
    SmallBox,
    MeetCard,
    ContentBox,
    OurGuide,
  },
});
</script>
